jQuery(document).ready(function($) {

		/** Quick location switcher 
			used in the category.php page for authors **/
		$('.category-picker').on('change', function(){
			window.location = $(this).val();
		})	

		function ccannon_simple_modal($form){
			$form = typeof($form) !== 'undefined' ? $($form) : $('#ccannon-mailchimp-footer');
			
			$form.on('submit', function(e){
				$('#consent').text( '' );
				e.preventDefault();
				if( !$('#gdpr')[0].checked ){
					$('#consent').text('Please consent');
					$('.checkbox--gdpr .outer').addClass( '.require-gdpr');
					return false;
				}
				$form.addClass('loading');
				var $data = $form.serialize();
				$('input, button', this).prop('disabled', true);
				if( $('#at')[0].checked ) $url = 'https://whistleblower-insider.us9.list-manage.com/subscribe/post-json?u=76484deb5aaed373a4f6a0b3f&amp;id=8692f6ab76' ;
				if( $('#wbi')[0].checked ) $url = 'https://whistleblower-insider.us9.list-manage.com/subscribe/post-json?u=76484deb5aaed373a4f6a0b3f&amp;id=61cea947a4' ;
				if( $('#at-pay')[0].checked ) $url = 'https://constantinecannon.us9.list-manage.com/subscribe/post-json?u=76484deb5aaed373a4f6a0b3f&amp;id=1051fe5a2e';
				if( $('#art-law')[0].checked ) $url = 'https://constantinecannon.us9.list-manage.com/subscribe/post-json?u=76484deb5aaed373a4f6a0b3f&amp;id=39b46370ee';
			  $.ajax({
		      type: "GET",
		      url:  $url ,
		      data: $data,
		      cache: false,
		      dataType: "jsonp",
		      jsonp: "c", // trigger MailChimp to return a JSONP response
		      contentType: "application/json; charset=utf-8",
		      error: function(error){
						$(this).removeClass('loading');
						$('input, button', this).removeProp('disabled');
						console.log( error )
		      },
		      success: function(data){
			      mailchimp_success(data, $form)
			   }
				})
				return false;
			})
		}
		ccannon_simple_modal();


	/**
	 * mailchimp_success function.
	 * 
	 * @access public
	 * @param mixed data
	 * @param mixed $form
	 * @return void
	 */
	function mailchimp_success(data, $form){
		console.log( data.result )
  	if(data.result === 'success'){
//     	$('#mc-footer-success .modal-title').text('Almost Done');
    	$('input', $form).val('');
			$form.removeClass('loading');
			$('#mc-footer-success .modal-message').html('<p>' + data.msg + '</p>');
    	$('#mc-footer-success').modal('show');
  	}else if(data.result === 'error'){
    	$('#mc-footer-success .modal-title').text('Subscription Error');
			$form.removeClass('loading');
			$('input, button', $form).removeProp('disabled');
			$('#mc-footer-success .modal-message').html('<p>' + data.msg + '</p>');
    	$('#mc-footer-success').modal('show');
    }
		$form.removeClass('loading').removeProp('disabled');
	}



	
    /* !----------------- SCROLL MAGIC ------------------! */
	if( $('body').hasClass('desktop') == true || $(window).width() > 767){	
		
		var controller = new ScrollMagic.Controller();		
		
		/* 
		 *
		 * -------------------------- MENU SQUISH
		 *
		 */
		var scene = new ScrollMagic.Scene({triggerElement: ".menu-trigger"})
			.setClassToggle(".main-header", "squish")
			.setPin(".main-header")
			//.addIndicators() // add indicators (requires plugin)
			.addTo(controller);
			
		
		if( $("#horz-sub-nav").length && $(".hsn-trigger").length ) {
			var controller = new ScrollMagic.Controller();
			// build scene
			var scene = new ScrollMagic.Scene({triggerElement: ".hsn-trigger"})
				.setClassToggle( '#horz-sub-nav', 'pinned')
				//.addIndicators({name: "trigger subnav"}) // add indicators (requires plugin)
				.addTo(controller);
		}	
    }
    
    var swiper3 = new Swiper('.row-3-columns.has-slides', {
		navigation: {
        	nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
      	},
        pagination: {
        	el: '.swiper-pagination',
			clickable: true,
      	},
      	slidesPerView: 3,
        slidesPerGroup: 3,
        // Responsive breakpoints
		breakpoints: {
		    // when window width is <= 767px
		    767: {
		      slidesPerView: 1,
		      slidesPerGroup: 1,
		    }
		}
    });
   
    var swiper4 = new Swiper('.row-4-columns.has-slides', {
	    navigation: {
        	nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
      	},
      	pagination: {
        	el: '.swiper-pagination',
			clickable: true,
      	},
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 10,
        // Responsive breakpoints
		breakpoints: {
		    // when window width is <= 767px
		    767: {
		      slidesPerView: 1,
		      slidesPerGroup: 1
		    }
		}
    });

	$('.menu-search i').on('click', function(){
		$('.menu-search').toggleClass('active');
	});
	
	$('#sticky-contact.big').on('click', function(){
		$(this).toggleClass('open');
	});
	
	
	//----- expand all on accordions
	$('.accordion-parent').each(function (index, value){
		var thisAccordion = $(this).find('.accordion');
			thisAccordion = thisAccordion.attr('id');
		var thisExpander = $(this).find('.expander');
		var thisHider = $(this).find('.hider');
		
		thisExpander.on('click', function(){
			$(this).hide();
			thisHider.show();
			$('#'+thisAccordion + ' .collapse').collapse('show');
		});

		thisHider.on('click', function(){
			$(this).hide();
			thisExpander.show();
			$('#'+thisAccordion + ' .collapse').collapse('hide');
		});
	});
	
	//add Active class to accordions
	$('.accordion')
		.on('shown.bs.collapse', function(e){
			$(e.target).siblings('.card-header').addClass('active');
		})
		.on('hidden.bs.collapse', function(e){
			$(e.target).siblings('.card-header').removeClass('active');
		});
	
	function ContactStick(){
		var controller = new ScrollMagic.Controller();
		var scene = new ScrollMagic.Scene({triggerElement: ".contact-trigger"})
		
			.setClassToggle( '#sticky-contact', 'pinned')
			//.addIndicators({name: "pincontact"})
			.addTo(controller);
	}
	ContactStick();
	
	function ITIHAWBCStick(){
		var controller = new ScrollMagic.Controller();
		var scene = new ScrollMagic.Scene({triggerElement: "#question-trigger"})
		
			.setClassToggle( 'ul.filters', 'pinned')
			//.addIndicators({name: "trigsubnav"})
			.addTo(controller);
	}
	ITIHAWBCStick();
	
	$('.navbar-toggler').on('click', function (e) {
		$(this).toggleClass('nav-open');    
	});
	
	$('.wsub .navbar-toggler').on('click', function (e) { 
		$('.wb-number').toggleClass('open');  
	});

	
	$('ul#menu-tags > li.menu-item-has-children > a').on('click', function (e) {
	e.preventDefault();
		$(this).parent().toggleClass('menu-open');    
	});
	
	$('ul#menu-tax-tags > li.menu-item-has-children > a').on('click', function (e) {
	e.preventDefault();
		$(this).parent().toggleClass('menu-open');    
		
	});
	
	//office locations on home page 
	$('.taphover').on('touchstart', function (e) {
	    'use strict'; //satisfy code inspectors
	    var link = $(this); //preselect the link
	    if (link.hasClass('hover')) {
	        return true;
	    } else {
	        link.addClass('hover');
	        $('.taphover').not(this).removeClass('hover');
	        e.preventDefault();
	        return false; //extra, and to make sure the function has consistent return points
	    }
	});
	
		

		
	//open links in a new tab if not cc
	function external_linker(){
		var domain = window.location.hostname;
		domain = domain.substring(domain.indexOf('.') + 1);
		//console.log(domain);
		var not = '[href*="' + domain + '"]';
		$("a[href^='http']").not(not).attr('target','_blank').addClass( 'externalLink' );
	}
	external_linker();
	
});